export const wytgColumn = [
    {
        prop: "financeProductId",
        label: "产品编号",
        width: 100,

    },
    {
        prop: "title",
        label: "产品名称",
    },

    {
        prop: "departName",
        label: "所属机构",
    },
    {
        prop: "InterestRate",
        label: "利率",
        template:true
    },
    {
        prop: "Money",
        label: "贷款限额",
        width: 140,
        template:true
    },
    {
        prop: "Time",
        label: "贷款期限",
        width: 140,
        template:true
    },
    {
        prop: "publishTime",
        label: "发布时间",
        width: 140,
    },
    {
        prop: "status",
        label: "启用状态",
        template:true,
    },
    {
      prop: "creatName",
      label: "操作人",
      width: 140,
      template: true,
  },
    {
        prop: "operation",
        label: "操作",
        width: 140,
        template: true,
    },
]
