<template>
  <div class="financialProduct">
    <!-- AddEdit新增、编辑页面 -->
    <AddEdit v-if="showEdit" @closeDialog="closeDialog" ref="addEdit" />
    <div class="form-list">
      <div class="table-search">
        <h3>金融产品</h3>
        <!-- 列表搜索条件，已封装 -->
        <FormQuery
          ref="formquery"
          :data="Query"
          :key="keyForm"
          @submit="search"
          @reset="reset"
        >
        </FormQuery>
        <div class="tools">
          <el-button type="success" @click="add">添加产品</el-button>
        </div>
      </div>
      <div class="table-content">
        <table-page
          :data="tableData"
          :columns="columns"
          :currentPage="query.pnum"
          :pageSize="query.psize"
          :total="total"
          :loading="loading"
          :border="true"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
          @selectionChange="handleSelection"
        >
          <template slot="InterestRate" slot-scope="scope">
            <span>
              {{
                scope.row.minRate == scope.row.maxRate
                  ? scope.row.maxRate + "%"
                  : scope.row.minRate + "% ~" + scope.row.maxRate + "% "
              }}</span
            >
          </template>
          <template slot="Money" slot-scope="scope">
            <!-- <span>
              {{ scope.row.minMoney }} 万元~{{ scope.row.maxMoney }}万元
            </span> -->
            <span>
              {{
                scope.row.minMoney == scope.row.maxMoney ||
                scope.row.minMoney == 0
                  ? scope.row.maxMoney + "元"
                  : scope.row.minMoney + "元~" + scope.row.maxMoney + "元"
              }}
            </span>
          </template>
          <template slot="Time" slot-scope="scope">
            <span>
              {{
                scope.row.minMoney == scope.row.maxMoney ||
                scope.row.minMoney == 0
                  ? scope.row.maxTime + "个月"
                  : scope.row.minTime + "个月~" + scope.row.maxTime + "个月"
              }}

              <!-- {{ FinancialDetails.minTime }}个月~{{
                FinancialDetails.maxTime
              }}个月 -->
            </span>
          </template>
          <template slot="status" slot-scope="scope">
            <span>{{ scope.row.status == 1 ? "启用" : "禁用" }}</span>
          </template>
          <template slot="creatName" slot-scope="scope">
            <span>{{ scope.row.creatName }}</span>
          </template>
          <template slot="operation" slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="detailClick(scope.row.financeProductId)"
              >查看</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="
                OnOrOff(
                  scope.row.financeProductId,
                  scope.row.status,
                  scope.row.title
                )
              "
            >
              {{ scope.row.status == 1 ? "禁用" : "启用" }}
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="editClick(scope.row.financeProductId, scope.row)"
              >修改</el-button
            >
          </template>
        </table-page>
      </div>
    </div>
  </div>
</template>

<script>
import FormQuery from "@/components/form/form-search";
import TablePage from "@/components/table/table-page";
import { recordInquiry, OnOrOff } from "@/api/RuralFinance";
import AddEdit from "./component/addEdit";
import { wytgColumn } from "./tableColumn";

const Query = [
  {
    name: "financeProductId",
    label: "产品编号",
    componentType: "input",
    inputType: "",
    placeholder: "请选择",
    width: "160px",
    dataOptions: [],
  },
  {
    name: "title",
    label: "产品名称：",
    componentType: "input",
    placeholder: "请选择",
    width: "140px",
    inputType: "",
    dataOptions: [],
  },
  {
    name: "departName",
    label: "机构名称",
    componentType: "input",
    placeholder: "请选择",
    width: "120px",
    dataOptions: [],
  },
  {
    name: "status",
    label: "启用状态",
    componentType: "select",
    placeholder: "请选择",
    width: "180px",
    dataOptions: [
      {
        name: "启用",
        value: 1,
      },
      {
        name: "未启用",
        value: 2,
      },
    ],
  },
];

export default {
  name: "AgriculturalAssistanceProject9Index",
  data() {
    return {
      Query: Query, //搜索列表
      tableData: [], //产品管理列表
      loading: false,
      showEdit: false, //新增编辑弹窗
      showDetail: false, //详情弹窗
      columns: wytgColumn, //产品管理标题
      query: {
        pnum: 1,
        psize: 10,
      }, //页数和总页码
      total: 0,
      keyForm: 1, //下拉框为请求来的数据时监听会用到
    };
  },
  components: {
    FormQuery,
    TablePage,
    AddEdit,
  },
  mounted() {
    //金融管理列表页数据
    this.recordInquiry();
  },
  methods: {
    async AllRecordInquiry(item) {
      // let areaCode = JSON.parse(localStorage.getItem("userInfo")).content
      //   .identityList[0].areaCode;
      let params = {
        pnum: item.pnum,
        psize: item.psize,
        financeProductId: item.financeProductId,
        title: item.title,
        departName: item.departName,
        status: item.status,
        minTime: item.minTime,
        maxTime: item.maxTime,
        minMoney: item.minMoney,
        maxMoney: item.maxMoney,
        creditType: item.creditType,
        otherCretieType: item.otherCretieType,
        minRate: item.minRate,
        maxRate: item.maxRate,
        areaCode: item.areaCode,
      };
      let res = await recordInquiry(params);
      this.tableData = res.data;
    },
    //金融管理列表页数据
    async recordInquiry() {
      let areaCode = JSON.parse(localStorage.getItem("userInfo")).content
        .identityList[0].areaCode;
      let params = {
        pnum: this.query.pnum,
        psize: this.query.psize,
        areaCode: areaCode,
      };

      let res = await recordInquiry(params);
      //显示分页总条数
      this.total = res.dataNum;
      this.tableData = res.data;
    },
    add() {
      // 显示发布的弹框
      this.showEdit = true;
    },
    //点击详情
    detailClick(financeProductId) {
      this.$router.push({
        path: "/home/LoanDetails",
        query: {
          // url的参数, 类似get请求的传参
          id: financeProductId,
        },
      });
    },
    // 新增编辑关闭弹框
    closeDialog(showEdit) {
      this.showEdit = showEdit;
      this.recordInquiry();
    },
    //关闭详情弹框
    closeDialog2() {
      this.showDetail = false;
    },
    handleSelection(selects) {
      console.log(selects);
    },
    //分页单页切换
    handleCurrentChange(page) {
      this.query.pnum = page;
      this.recordInquiry();
    },
    //分页总页数切换
    handleSizeChange(size) {
      this.query = {
        pnum: 1,
        psize: size,
      };
      this.recordInquiry();
    },
    //点击编辑按钮
    editClick(a, b) {
      this.showEdit = true;
      this.$nextTick(() => {
        this.$refs.addEdit.editObj(a, b);
      });
    },
    //金融产品停用启用状态
    async OnOrOff(financeProductId, status, title) {
      this.$confirm(`是否${status == 1 ? "禁用" : "启用"} ${title}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (status == "1") {
            status = "0";
          } else {
            status = "1";
          }
          let params = {
            financeProductId: financeProductId,
            status: status,
          };
          let res = await OnOrOff(params);
          this.recordInquiry();
          this.$message({
            type: "success",
            message: `产品已${status == 1 ? "启用" : "禁用"}`,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
      //重新加载数据
      this.recordInquiry();
    },

    // 点击搜索按钮
    async search(params) {
      // this.pnum = 1;
      // 组装条件
      let chuan = {
        pnum: this.query.pnum,
        psize: this.query.psize,
        ...params,
      };
      let res = await recordInquiry(chuan);
      this.tableData = res.data;
    },

    submit() {},
    // 搜索条件重置按钮
    async reset() {
      this.query = {
        pnum: 1,
        psize: 10,
      };
      let params = {
        pnum: this.query.pnum,
        psize: this.query.psize,
      };
      let res = await recordInquiry(params);
      this.tableData = res.data;
    },
  },
};
</script>
<style lang="less" scoped>
.form-list {
  .table-search {
    padding: 0 0 0 30px;
    /deep/.el-button {
      padding: 10px 20px !important;
    }
    .tools {
      margin-bottom: 18px;
      margin-top: 6px;
    }
  }
  .table-content {
    padding: 0 30px;
  }
}
</style>
